<!--
  PACKAGE_NAME : src\components\devextreme
  FILE_NAME : esp-dx-file-uploader
  AUTHOR : devyoon91
  DATE : 2024-09-20
  DESCRIPTION :
-->
<template>
  <div class="wrap-container">
<!--    <div :id="dropZoneId" class="dropzone" :class="[isDropZoneActive ? 'dropzone.active' : '']">-->
<!--      <img class="dropzone-image" :src="imageSource" v-if="imageSource" alt="" />-->
<!--      <div class="dropzone-text flex-box" v-if="dropZoneTextVisible">-->
<!--        <span>+</span>-->
<!--        <span>클릭 또는 파일을 드래그하세요.</span>-->
<!--      </div>-->
<!--      <DxProgressBar-->
<!--        id="upload-progress"-->
<!--        :min="0"-->
<!--        :max="100"-->
<!--        width="30%"-->
<!--        :show-status="false"-->
<!--        :visible="dropZoneProgressVisible"-->
<!--        :value="progressValue"-->
<!--      />-->
<!--    </div>-->
    <DxFileUploader
      :accept="accept"
      :accessKey="accessKey"
      :ref="refName"
      :name="name"
      :multiple="multiple"
      :uploadMode="uploadMode"
      :uploadUrl="uploadUrl"
      :showFileList="showFileList"
      :width="width"
      :height="height"
      :readOnly="readOnly"
      :hint="hint"
      :allowCanceling="allowCanceling"
      :labelText="computedLabelText"
      :selectButtonText="computedSelectButtonText"
      :allowedFileExtensions="allowedFileExtensions"
      :maxFileSize="maxFileSize"
      :minFileSize="minFileSize"
      :dropZone="dropZone"
      :dialogTrigger="dialogTrigger"
      :visible="visible"
      :activeStateEnabled="activeStateEnabled"
      @progress="handleProgress"
      @uploaded="handleUploaded"
      @uploadStarted="handleUploadStarted"
      @uploadAborted="handleUploadAborted"
      @uploadError="handleUploadError"
      @valueChanged="handleValueChanged"
      @dropZoneEnter="dropZoneEnter"
      @dropZoneLeave="dropZoneLeave"
    />
  </div>
</template>

<script>
  import { DxFileUploader } from 'devextreme-vue/file-uploader';
  // import { DxProgressBar } from 'devextreme-vue/progress-bar';
  import { espApi } from '@/api';

  export default {
    components: { DxFileUploader },
    props: {
      /**
       * 파일 선택 가능한 확장자
       * - MIME type 형식으로 입력 (ex. image/*, application/pdf)
       * - 기본값 : '*' (모든 파일)
       */
      accept: {
        type: String,
        default: '*',
      },
      /**
       * UI 구성 요소에 포커스를 설정하는 바로 가기 키를 지정합니다.
       * - 기본값 : undefined
       */
      accessKey: {
        type: String,
        default: undefined,
      },
      /**
       * 파일 업로더 참조(ref)
       * - 기본값 : undefined
       */
      refName: {
        type: String,
        default: undefined,
      },
      /**
       * 파일 이름
       * - 파일 업로드시 사용되는 이름
       * - 기본값 : file
       */
      name: {
        type: String,
        default: 'file',
      },
      /**
       * 파일 다중 선택 여부
       * - 기본값 : false
       */
      multiple: {
        type: Boolean,
        default: false,
      },
      /**
       * 파일 업로드 모드
       * - instantly : 파일 선택 후 즉시 업로드
       * - useButtons : 파일 선택 후 업로드 버튼 클릭
       * - useForm : 파일 선택 후 폼 제출
       * - 기본값 : useForm
       */
      uploadMode: {
        type: String,
        default: 'useForm',
      },
      /**
       * 파일 업로드 URL
       * - ESP 첨부파일 API 호출시 공백으로 처리
       * - 기본값 : ''
       */
      uploadUrl: {
        type: String,
        default: '',
      },
      /**
       * 파일 리스트 표시 여부
       * - 기본값 : true
       */
      showFileList: {
        type: Boolean,
        default: true,
      },
      width: {
        type: String,
        default: '100%',
      },
      height: {
        type: String,
        default: '100%',
      },
      /**
       * 파일 선택 레이블 텍스트
       * - 기본값 : '파일 선택' (다국어 처리, COMPONENTS.FILE_SELECT)
       */
      labelText: {
        type: String,
        default: '',
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      hint: {
        type: String,
        default: '',
      },
      /**
       * 파일 업로드 취소 가능 여부
       * - 기본값 : true
       */
      allowCanceling: {
        type: Boolean,
        default: true,
      },
      /**
       * 파일 선택 버튼 텍스트
       * - 기본값 : '파일 선택'
       */
      selectButtonText: {
        type: String,
        default: '',
      },
      /**
       * 허용 파일 확장자
       * - 기본값 : []
       */
      allowedFileExtensions: {
        type: Array,
        default: () => [],
      },
      /**
       * 최대 파일 크기
       * - 기본값 : 0 (제한 없음)
       */
      maxFileSize: {
        type: Number,
        default: 0,
      },
      /**
       * 최소 파일 크기
       * - 기본값 : 0 (제한 없음)
       */
      minFileSize: {
        type: Number,
        default: 0,
      },
      /**
       * 드롭존 설정 (String | HTMLElement | jQuery) <br>
       * 사용자가 파일을 끌어서 놓아 업로드할 수 있는 HTML 요소를 지정합니다
       * - 기본값 : undefined
       */
      dropZone: {
        type: String | HTMLElement | jQuery,
        default: undefined,
      },
      /**
       * 파일 업로드 대화 상자를 호출하는 HTML 요소를 지정합니다
       * - 기본값 : undefined
       */
      dialogTrigger: {
        type: String | HTMLElement | jQuery,
        default: undefined,
      },
      /**
       * 파일 업로더 표시 여부
       * - 기본값 : true
       */
      visible: {
        type: Boolean,
        default: true,
      },
      /**
       * 활성 상태 활성화 여부
       * - 기본값 : false
       */
      activeStateEnabled: {
        type: Boolean,
        default: false,
      },
      // /**
      //  * 드롭존 활성화 여부
      //  * - 기본값 : false
      //  */
      // isDropZoneActive: {
      //   type: Boolean,
      //   default: false,
      // },
      // /**
      //  * 드롭존 활성화 여부가 true 일때 진행률 표시
      //  * - 기본값 : true
      //  */
      // dropZoneProgressVisible: {
      //   type: Boolean,
      //   default: true,
      // },
      // /**
      //  * 드롭존 텍스트 표시 여부
      //  * - 기본값 : true
      //  */
      // dropZoneTextVisible: {
      //   type: Boolean,
      //   default: true,
      // },
      // /**
      //  * 드롭존 ID
      //  * - 기본값 : 랜덤아이디 생성
      //  */
      // dropZoneId: {
      //   type: String,
      //   default: undefined,
      // },
    },
    data() {
      return {
        imageSource: '', // 드롭존 이미지 소스, 기본값 없음
        fileList: [], // 업로드 준비된 파일 리스트
        labelTextComputed: '', // 기본 값을 빈 문자열로 설정
        // progressValue: 0, // 업로드 진행률
        // isDropZoneActive: false, // 드롭존 활성화 여부
        // _dropZone: this.dropZone,
        // _dialogTrigger: this.dialogTrigger,
      };
    },
    watch: {
      /**
       * 업로드 준비된 파일 리스트 변경 감지
       * 부모 객체에서 v-model로 양방향 바인딩시 사용
       */
      fileList: {
        handler(newValue) {
          // 즉시 업로드 모드가 아닐 경우 v-model 로 부모 객체에 전달
          if (this.uploadMode !== 'instantly') {
            this.$emit('input', newValue); // v-model 지원
          }
        },
        deep: true,
      },
      // dropZoneId: {
      //   handler(newVal) {
      //     console.log('dropZoneId가 변경되었습니다: ', newVal);
      //     this._dropZone = newVal;
      //     this.dialogTrigger = newVal;
      //   },
      //   deep: true,
      // },
      // /**
      //  * 드롭존 이미지 소스 변경 감지
      //  */
      // imageSource: {
      //   handler(newValue) {
      //     this.$emit('input', newValue); // v-model 지원
      //   },
      // },
    },
    computed: {
      computedLabelText() {
        return this.labelText || this.$_msgContents('COMPONENTS.DROP_FILE', { defaultValue: '또는 여기에 파일을 드롭하세요' });
      },
      computedSelectButtonText() {
        return this.selectButtonText || this.$_msgContents('COMPONENTS.FILE_SELECT', { defaultValue: '파일 선택' });
      },
    },
    methods: {
      /**
       * 파일 업로드 진행 이벤트
       *
       * @param e
       */
      handleProgress(e) {
        if (!this.$listeners['progress']) {
          // 미구현
        }
        this.$emit('progress', e);
      },
      /**
       * 파일 업로드 완료 이벤트
       *
       * @param e
       */
      handleUploaded(e) {
        if (!this.$listeners['uploaded']) {
          // 미구현
        }
        this.$emit('uploaded', e);
      },
      /**
       * 파일 업로드 시작 이벤트
       *
       * @param e
       */
      handleUploadStarted(e) {
        if (!this.$listeners['uploadStarted']) {
          // 미구현
        }
        this.$emit('uploadStarted', e);
      },
      /**
       * 파일 업로드 취소 이벤트
       *
       * @param e
       */
      handleUploadAborted(e) {
        if (!this.$listeners['uploadAborted']) {
          // 미구현
        }
        this.$emit('uploadAborted', e);
      },
      /**
       * 파일 업로드 오류 이벤트
       *
       * @param e
       */
      handleUploadError(e) {
        if (!this.$listeners['uploadError']) {
          // 미구현
        }
        this.$emit('uploadError', e);
      },
      /**
       * 파일 선택 이벤트
       *
       * @param e
       */
      async handleValueChanged(e) {
        this.fileList = e.value;
        if (!this.$listeners['valueChanged']) {
          // 즉시 업로드 모드일 경우 파일 업로드
          if (this.uploadMode === 'instantly') {
            await espApi.fileUpload(e);
          }
        }
        this.$emit('valueChanged', e);
      },
      /**
       * 드롭존 진입 이벤트
       *
       * @param e
       */
      dropZoneEnter(e) {
        if (!this.$listeners['dropZoneEnter']) {
          // 미구현
        }
        this.$emit('dropZoneEnter', e);
      },
      /**
       * 드롭존 이탈 이벤트
       *
       * @param e
       */
      dropZoneLeave(e) {
        if (!this.$listeners['dropZoneLeave']) {
          // 미구현
        }
        this.$emit('dropZoneLeave', e);
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  //.wrap-container {
  //  width: 100%;
  //  text-align: center;
  //}
  //
  //.dropzone {
  //  /* 반응형 스타일을 위한 기본 크기 */
  //  display: flex;
  //  flex-direction: column;
  //  align-items: center;
  //  justify-content: center;
  //  padding: 20px;
  //  border: 2px dashed #ccc;
  //  min-height: 150px;
  //}
  //
  //.dropzone.active {
  //  border-color: #90caf9; /* 활성화 시 변경할 색상 */
  //}
  //
  //.dropzone-image {
  //  max-width: 100%;
  //  height: auto;
  //}
  //
  //.plus-icon {
  //  font-weight: bold;
  //  font-size: 3vw; /* 뷰포트 단위 사용 */
  //}
  //
  //.drag-text {
  //  font-size: 1.5vw;
  //}
  //
  //@media (max-width: 600px) {
  //  .dropzone {
  //    padding: 10px;
  //  }
  //
  //  .plus-icon {
  //    font-size: 6vw; /* 작은 화면에서 더 큰 글자 크기 */
  //  }
  //
  //  .drag-text {
  //    font-size: 3vw;
  //  }
  //}
  //
  //.dropzone-text > span {
  //  font-weight: 100;
  //  opacity: 0.5;
  //}
</style>
